<template>
  <app-modal title="Tag page edition" :width="900" @submit="onSubmit">
    <div class="grid gap-4">
      <tag-page-visibility-select v-model:public="form.public" />

      <tag-autocomplete
        v-model="form.tag_id"
        data-cy-main-tag
        :exclude="list.map((item) => item.tag_id)"
        label="Tag"
        required
      />

      <div>
        <app-draggable-list v-model="form.sections" item-key="id">
          <template #item="{ item, index }">
            <div
              class="mb-8 grid grid-cols-[1fr_auto] gap-4 pl-3 pt-3"
              data-cy-tag-page-section
            >
              <div>
                <app-form-input-text
                  v-model="form.sections[index]!.title"
                  data-cy-title
                  label="Title"
                  :name="`Title ${item.id}`"
                  required
                />

                <app-form-textarea
                  v-model="form.sections[index]!.description"
                  data-cy-description
                  label="Description"
                  :name="`Description ${item.id}`"
                />

                <tag-autocomplete
                  v-model="form.sections[index]!.tags"
                  :children_of="form.tag_id"
                  data-cy-tags
                  label="Related tags"
                  multiple
                  :name="`Tags ${item.id}`"
                  required
                />
              </div>

              <v-btn
                color="error"
                icon="ph:trash"
                size="small"
                variant="tonal"
                @click="deleteSection(form.sections[index]!)"
              />
            </div>
          </template>
        </app-draggable-list>

        <v-btn
          class="mb-6"
          color="primary"
          data-cy-create-section
          prepend-icon="ph:plus"
          size="small"
          variant="tonal"
          @click="addSection"
          >Add section</v-btn
        >
      </div>
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { uniqueId } from "lodash";

import type {
  TagPageCreationRequest,
  TagPageView,
} from "~/business-areas/tag-page/tag-page.model";

const properties = defineProps<{
  page?: TagPageView;
  list: TagPageView[];
}>();

const form = reactive<TagPageCreationRequest & { public: boolean }>({
  id: properties.page?.id,
  tag_id:
    properties.page?.tag_id ??
    (undefined as unknown as DatabaseTable<"tag_pages">["tag_id"]),
  public: properties.page?.public ?? false,
  order: properties.list.length,
  sections:
    properties.page?.sections.map((s) => {
      return {
        id: s.id,
        title: s.title,
        description: s.description,
        tags: s.tags.map((t) => t.id),
      };
    }) ?? [],
});

function onSubmit() {
  return useModal("tagPageEditionModal").close({
    confirmed: true,
    payload: form,
  });
}

function addSection() {
  form.sections.push({
    id: uniqueId("section_"),
    title: "",
    description: "",
    tags: [],
  });
}

function deleteSection(section: (typeof form.sections)[number]) {
  form.sections = form.sections.filter((s) => s.id !== section.id);
}
</script>
